import { mapActions, mapState } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'
import UserAttributes from '@/helpers/UserAttributes'

export default {
  mixins: [mixinAlert],
  data: () => ({
    filters: {},
    path: '',
    loading: false,
    regexNumbersHifen: new RegExp('^[0-9.-]*$'),
    isAdmin: false,
  }),
  async created() {
    this.isAdmin = await UserAttributes('profile')
    this.isAdmin = this.isAdmin === 'admin'
  },
  async mounted() {
    await this.getCompanyCodes()
  },
  methods: {
    async getCompanyCodes() {
      let currentUserEmail = await UserAttributes('email')
      await this.setCompanyCodeDataToList({ email: currentUserEmail })
    },
    async userMail() {
      return UserAttributes('mail')
    },
    addValueToMultipleFilter(value, filterProp) {
      let preparedData = value
      if (['nfNumber', 'nfeNumber'].includes(filterProp)) {
        preparedData = this.prepareData(value, 9)
      }

      if (filterProp == 'docNumber') {
        preparedData = this.prepareData(value, 10)
      }
      if (this.regexNumbersHifen.test(value)) {
        if (!this.filters[filterProp].includes(preparedData)) {
          this.filters[filterProp].push(preparedData)
        }
      }
    },
    pushManualValue(evt, filterProp) {
      evt.preventDefault()
      if (evt.target.value) {
        this.addValueToMultipleFilter(evt.target.value, filterProp)
      }
    },
    prepareData(data, length) {
      return data.padStart(length, '0')
    },
    pasteData(evt, filterProp) {
      let pasteData = evt.clipboardData.getData('text').split(/\r?\n/g)
      pasteData.forEach((data) => {
        if (data) {
          this.addValueToMultipleFilter(data, filterProp)
        }
      })
    },
    clearFilterForm() {
      this.$refs.filterForm.reset()
      this.cleanupData()
    },
    cleanupData() {
      const keys = Object.keys(this.filters)
      keys.forEach((key) => {
        if (typeof this.filters[key] == 'object') {
          this.filters[key] = {}
        }
        this.filters[key] = ''
      })
    },
    prepareFilter() {
      return this.filters
    },
    async submitFilter() {
      if (!this.$refs.filterForm.validate()) {
        return
      }
      this.clearState(false)
      this.loading = true
      this.$root.$emit('clear-input-filters')
      const filter = this.prepareFilter()
      let result = await this.setDataToList(filter)
      this.loading = false

      if (result instanceof Error || (result.typeMessage && ['E', ''].includes(result.typeMessage)) || result.message) {
        const message = result.message ? result.message : ''
        await this.mixinAlertError(message)
      }
    },
    ...mapActions({
      setDataToList(dispatch, payload) {
        return dispatch(this.getPathStore + '/setDataToList', payload)
      },
      clearState(dispatch) {
        return dispatch(this.getPathStore + '/clearState')
      },
    }),
    updatePayloadWithQueryString() {
      const { query } = this.$route
      const keys = Object.keys(this.filters)
      keys.forEach((key) => {
        this.filters[key] = query[key] || null
      })
    },
    verifyNullableFilter(obj, strField, value, concat) {
      if (value.length) {
        obj[strField] = `${value}${concat || ''}`
      }
    },
    ...mapActions('userStore', {
      setCompanyCodeDataToList: 'setCompanyCodeDataToList',
    }),
  },
  computed: {
    filtroEmpresa() {
      return this.isAdmin ? this.$t('sapCode') : this.$t('companyCode')
    },
    ...mapState({
      getCompanyCodeList(_, getters) {
        return getters['userStore/getCompanyCodeList']
      },
    }),
    companyCodeList() {
      let chave = this.isAdmin ? 'sapCode' : 'companyCode'
      return this.getCompanyCodeList.map((el) => ({
        text: el[chave] || el.companyCode,
        value: el.companyCode,
      }))
    },
    cptdTotalFilters() {
      return Object.keys(this.filters)
        .map((key) =>
          this.filters[key] ||
          (typeof this.filters[key] == 'object' && Object.keys(this.filters[key]).length !== 0) ||
          (this.filters[key] && this.filters[key].length)
            ? 1
            : 0
        )
        .reduce((a, b) => a + b)
    },
    getPathStore() {
      return `${this.path}Store`
    },
  },
}
