<template>
  <v-form ref="filterForm" @submit.prevent="submitFilter()">
    <v-row dense>
      <v-col cols="12" md="2">
        <v-text-field v-model="filters.cnpj" :label="$t('companyCode')" outlined dense />
      </v-col>
      <v-col cols="12" md="2">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="5">
            <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <TLoading v-model="loading" />
  </v-form>
</template>

<script>
import mixinFilters from '@/mixins/mixinFilter'

export default {
  name: 'CompaniesFilterComponent',
  mixins: [mixinFilters],
  data() {
    return {
      path: 'companiesConfig',
      filters: {
        cnpj: '',
      },
    }
  },
}
</script>
